import Link from "next/link";
import React, { useState } from "react";
import { useRouter } from "next/router";
import ReactSlider from "react-slider";
import Switch from "react-switch";
import TippyIcon from "../../components/TippyIcon";
import Dots from "./../../../public/home/dots.webp";
import Ticket from "../../pages/Checkout/RegistrationComponents/SubComponents/CartBox/Settlements/calculations";
import { GOLD_PLAN, LITE_PLAN, SILVER_PLAN } from "../../config/constants";

const rootClassName = "ticket_calculator";
const HomeCostCalc = ({ copyToClipboard }) => {
	const router = useRouter();

	const [currentTicketValue, setCurrentTicketValue] = useState(100);
	const [currentParticipants, setParticipants] = useState(1);
	const [isTaxChecked, setIsTaxChecked] = useState(false);
	const [organiserKonfhubFee, setOrganiserKonfhubFee] = useState(false); //Processing Fee
	const [participantKonfhubFee, setParticipantKonfhubFee] = useState(true); //Processing Fee
	const [organiserTaxCharge, setOrganiserTaxCharge] = useState(false); //tax charges
	const [participantTaxCharge, setParticipantTaxCharge] = useState(false); //tax charges
	const [taxPercentageOnCalculator, setTaxPercentageOnCalculator] = useState(18);
	const [openTotal, setOpenTotal] = useState(false);
	const [openPlatformFee, setOpenPlatformFee] = useState(false);
	const [isNotINR, setIsNotINR] = useState(false);

	const [activeTab, setActiveTab] = useState(SILVER_PLAN);

	const planBasedProcessingFees = {
		Lite: 2, // Lite
		Silver: 3.75, // Silver
		Gold: 4.25 // Gold
	};

	let originalPrice = currentTicketValue * currentParticipants;
	let isTaxable = participantTaxCharge;
	let isTaxInclusive = participantTaxCharge;
	let taxPercentage = taxPercentageOnCalculator;
	let isPgChargeInclusive = participantKonfhubFee;
	let pgChargesPercentage = planBasedProcessingFees[activeTab];
	let pvt_discount = 0;
	let group_discount = 0;
	let isTaxOnPgInclusive = false;
	let taxPercentageOnPg = 18;
	let basePgPercentage = planBasedProcessingFees[activeTab] / (1 + 18 / 100);

	const ticket = new Ticket(
		originalPrice,
		isTaxable,
		isTaxInclusive,
		taxPercentage,
		isPgChargeInclusive,
		pgChargesPercentage,
		pvt_discount,
		group_discount,
		isTaxOnPgInclusive,
		taxPercentageOnPg,
		basePgPercentage
	);
	const ticketPriceDetails = ticket.calculate();

	const pgFee = (ticketPriceDetails.payableAmount * 2) / 100;
	const pgFeeTax = (pgFee * 18) / 100;
	const totalPGFee = (Number(pgFee) + Number(pgFeeTax)).toFixed(2);
	const platformFee = (Number(ticketPriceDetails.khFee) - Number(totalPGFee)).toFixed(2);

	const taxHandle = e => {
		setIsTaxChecked(!isTaxChecked);
		if (e.target.checked == true) {
			setParticipantTaxCharge(true);
		} else {
			setParticipantTaxCharge(false);
			setOrganiserTaxCharge(false);
		}
	};

	const handleCurrency = () => {
		setIsNotINR(!isNotINR);
	};

	const changeTab = tab => {
		setActiveTab(tab);
		if (tab === LITE_PLAN) {
			setOpenPlatformFee(false);
		}
	};

    const handleChange = (e, isCurrentTicketValue = true) => {
        const inputValue = e.target.value;
    
        if (inputValue === '' || parseInt(inputValue, 10) <= 10000000) {
          if(isCurrentTicketValue){
            setCurrentTicketValue(inputValue);
          } else {
            setParticipants(inputValue)
          }
        }
    }; 

	return (
		<>
			<div className="cost-container py-md-3 px-md-5 py-5 px-2" id="cost-calculator">
				<div className="row g-0">
					<div className="col py-md-5">
						<h2 className="awards-title text-center">
							Save your cost with{" "}
							<span className="theme-text">
								{" "}
								<p className="d-inline-block">
									KonfHub{""}
									{router.pathname === "/pricing" && (
										<i
											onClick={() => copyToClipboard("cost-calculator")}
											className="fa-solid fa-link price-calc-icon"
										></i>
									)}
								</p>
							</span>
						</h2>
						<div className="awards-sub-title w-75 mx-auto text-center">
							No upfront, set-up or subscription charges. We are free for free tickets
							and KonfHub Fee 1.75% per ticket plus payment gateway fee and applicable
							taxes
						</div>
					</div>
				</div>
				<div className="row g-0 py-3 py-md-0 pb-md-5 d-flex justify-content-center">
					<div className="ticket-price-calc">
						<div className="row w-100 ml-0 d-flex justify-content-between">
							<div className="d-flex justify-content-between align-items-center">
								<h3 className="earning-head">Ticket Price Calculator</h3>
								{/*<span>
                                    <span className="currency-text">INR</span>
                                    <Switch
                                        name="analytics_cookies"
                                        height={22}
                                        width={50}
                                        className="ms-1 me-1"
                                        id="currency"
                                        offColor="#e1e1e1"
                                        onColor="#e1e1e1"
                                        onHandleColor='#572148'
                                        handleDiameter={19}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        checked={isNotINR}
                                        onChange={(e) => handleCurrency(e)}
                                    />
                                    <span className="currency-text">USD</span>

                                </span>*/}
							</div>
							<span className="earning-desc">
								Calculate your earnings from ticket sales
							</span>
						</div>

						<div className="d-flex calc-flex mt-5 mb-4 ml-2">
							<div className="col-7-main">
								<div className="custom_tabs d-flex justify-content-between align-items-center">
									<div
										className={`custom_tab d-flex align-items-center justify-content-center ${activeTab === LITE_PLAN ? "active" : ""}`}
										onClick={() => changeTab(LITE_PLAN)}
									>
										Lite Plan
									</div>
									<div
										className={`custom_tab d-flex align-items-center justify-content-center ${activeTab === SILVER_PLAN ? "active" : ""}`}
										onClick={() => changeTab(SILVER_PLAN)}
									>
										Silver Plan
									</div>
									<div
										className={`custom_tab d-flex align-items-center justify-content-center ${activeTab === GOLD_PLAN ? "active" : ""}`}
										onClick={() => changeTab(GOLD_PLAN)}
									>
										Gold Plan
									</div>
								</div>
								<div className="d-flex justify-content-between align-items-center mt-3">
									<span className="label_text">Ticket Price*</span>
									<span className="price-value-text">
										<input
											className="price-value-text input-box"
											type="number"
											min="0"
											max="10000000"
											value={currentTicketValue}
											onChange={e => handleChange(e)}
										/>
									</span>
								</div>
								{/* <div className="price-value-text mt-1">₹ {currentTicketValue}</div>
								<ReactSlider
									className="customSlider"
									trackClassName="customSlider-track"
									thumbClassName="customSlider-thumb"
									min={0}
									max={100000}
									minDistance={10}
									value={currentTicketValue}
									onChange={value => setCurrentTicketValue(value)}
									renderMark={props => {
										if (props.key < currentTicketValue) {
											props.className =
												"customSlider-mark customSlider-mark-before";
										} else if (props.key === currentTicketValue) {
											props.className =
												"customSlider-mark customSlider-mark-active";
										}
										return <span {...props} />;
									}}
								/> */}
								{/* <div className="d-flex justify-content-between mt-4 limit-text">
									<span className={`from_limit_text_${rootClassName}`}>
										From {isNotINR ? "$" : "₹"} 0
									</span>
									<span className={`from_limit_text_${rootClassName}`}>
										Limit {isNotINR ? "$" : "₹"} 1,00,000
									</span>
								</div> */}

								<div className="d-flex justify-content-between align-items-center mt-3">
									<span className="label-text">Attendees Per Event*</span>
									<div>
										<span>
											<input
												className="price-value-text input-box"
												type="number"
												min="0"
												max="10000000"
												value={currentParticipants}
												onChange={e => handleChange(e, false)}
											/>
										</span>
									</div>
								</div>
								{/* <div className="price-value-text mt-1">{currentParticipants} Participants</div>*/}
								{/* <ReactSlider
									className="customSlider"
									trackClassName="customSlider-track"
									thumbClassName="customSlider-thumb"
									min={0}
									max={100000}
									value={currentParticipants}
									onChange={value => setParticipants(value)}
									renderMark={props => {
										if (props.key < currentParticipants) {
											props.className =
												"customSlider-mark customSlider-mark-before";
										} else if (props.key === currentParticipants) {
											props.className =
												"customSlider-mark customSlider-mark-active";
										}
										return <span {...props} />;
									}}
								/> */}
								{/* <div className="d-flex justify-content-between mt-4 limit-text">
									<span className={`from_limit_text_${rootClassName}`}>
										From 0
									</span>
									<span className={`from_limit_text_${rootClassName}`}>
										Limit 1,00,000
									</span>
								</div> */}
								<div className="mt-5 row d-flex align-items-center">
									<span className="col-lg-6 col-md-12 col-sm-12">
										<input
											id="tax"
											type="checkbox"
											name="tax"
											className="is-checkradio checkbox pricing_calc_checkbox"
											// defaultChecked={true}
											checked={isTaxChecked}
											onChange={e => {
												taxHandle(e);
												setOpenTotal(false);
											}}
										/>
										<label htmlFor="tax" className="label-text_tax_applicable">
											Do you want to collect tax?
										</label>
									</span>
									{isTaxChecked && (
										<div className="label_text col-lg-6 col-md-12 col-sm-12 mt-0 mt-sm-4 mt-md-0 mt-lg-0">
											<span className="me-2 label-text_tax_applicable">
												Tax Percentage{" "}
											</span>{" "}
											<input
												id="taxPercentage"
												type="number"
												max={99}
												name="taxPercentage"
												className="taxPercentage-input"
												defaultValue={18}
												onChange={e =>
													setTaxPercentageOnCalculator(e.target.value)
												}
											/>
											<span className="tax_percentage_symbol ms-2">%</span>
										</div>
									)}
								</div>
								<div className="mt-4">
									{isTaxChecked && (
										<div className="row d-flex align-items-center">
											<span className="col-lg-8 col-md-12 col-sm-12">
												<label
													htmlFor=""
													className="label-text_tax_applicable"
												>
													Who will pay the tax charges?
												</label>
											</span>
											<span className="col-lg-4 col-md-12 col-sm-12 mt-3 mt-sm-4 mt-md-4 mt-lg-2">
												<div className="Tab-text">
													<span
														className={
															organiserTaxCharge
																? "color-background-two Organizer-text"
																: "white-background-two Organizer-text"
														}
														onClick={() => {
															setOrganiserTaxCharge(true);
															setParticipantTaxCharge(false);
														}}
													>
														Organizer
													</span>
													<span
														className={
															participantTaxCharge
																? "color-background-two Organizer-text"
																: "white-background-one Organizer-text"
														}
														onClick={() => {
															setParticipantTaxCharge(true);
															setOrganiserTaxCharge(false);
														}}
													>
														Participants
													</span>
												</div>
											</span>
										</div>
									)}
								</div>
								<div className="row tax-included mt-4 d-flex align-items-center">
									<span className="col-lg-8 col-md-12 col-sm-12">
										<label
											htmlFor="PG-tax"
											className="label-text_tax_applicable"
										>
											Who will pay the Payment Gateway fee & Platform Fee?
										</label>
									</span>
									<span className="col-lg-4 col-md-12 col-sm-12 mt-3 mt-sm-4 mt-md-4 mt-lg-2">
										<div className="Tab-text">
											<span
												className={
													organiserKonfhubFee
														? "color-background Organizer-text"
														: "white-background-two Organizer-text"
												}
												onClick={() => {
													setOrganiserKonfhubFee(true);
													setParticipantKonfhubFee(false);
												}}
											>
												Organizer
											</span>
											<span
												className={
													participantKonfhubFee
														? "color-background Organizer-text"
														: "white-background-one Organizer-text"
												}
												onClick={() => {
													setParticipantKonfhubFee(true);
													setOrganiserKonfhubFee(false);
												}}
											>
												Participants
											</span>
										</div>
									</span>
								</div>
							</div>
							<div className="col-5-main d-flex justify-content-center align-items-center h-100 mt-5 mt-lg-2">
								<div className="ticket-calculation">
									<div className="text-center">
										<p className={`paid_by_heading_${rootClassName} mb-0`}>
											Paid by participants
										</p>
										<p className="total-income mb-2 mt-2">
											{isNotINR ? "$" : "₹"}{" "}
											{Number(ticketPriceDetails.payableAmount).toFixed(2)}
										</p>
									</div>
									<div className="divider-line"></div>
									<div>
										<p className="other-fee-text d-flex justify-content-between mb-0">
											<span className={`breakdown_heading_${rootClassName}`}>
												Total
												{isTaxChecked && (
													<i
														className={
															openTotal
																? "fas fa-angle-up icons"
																: "fas fa-angle-down icons"
														}
														onClick={() => setOpenTotal(!openTotal)}
													></i>
												)}
											</span>
											<span className="price-text">
												{isNotINR ? "$" : "₹"}{" "}
												{Number(ticketPriceDetails.payableAmount).toFixed(
													2
												)}
											</span>
										</p>
										{openTotal && (
											<p className="hide-prices-text d-flex justify-content-between mb-0 hide-prices mt-2">
												<span
													className={`breakdown_subHeading_${rootClassName}`}
												>
													GST({taxPercentageOnCalculator}%):
												</span>
												<span className="hide-price-text-two">
													{isNotINR ? "$" : "₹"}{" "}
													{(organiserKonfhubFee && organiserTaxCharge) ||
													(participantTaxCharge && participantKonfhubFee)
														? Number(
																ticketPriceDetails.taxAmount
															).toFixed(2)
														: participantTaxCharge &&
															  organiserKonfhubFee
															? (
																	Number(
																		ticketPriceDetails.taxAmount
																	) +
																	Number(
																		ticketPriceDetails.taxOnPgCharges
																	)
																).toFixed(2)
															: 0}
												</span>
											</p>
										)}
									</div>
									<div className="mt-2">
										<p className="other-fee-text d-flex justify-content-between mb-0">
											<span className={`breakdown_heading_${rootClassName}`}>
												Processing Fee
												{activeTab !== LITE_PLAN && (
													<i
														className={
															openPlatformFee
																? "fas fa-angle-up icons"
																: "fas fa-angle-down  icons"
														}
														onClick={() =>
															setOpenPlatformFee(!openPlatformFee)
														}
													></i>
												)}
											</span>
											<span className="price-text">
												{isNotINR ? "$" : "₹"}{" "}
												{Number(ticketPriceDetails.khFee).toFixed(2)}
											</span>
										</p>
										{openPlatformFee && (
											<div className="hide-prices mt-2">
												<p className="hide-prices-text d-flex justify-content-between">
													<span
														className={`breakdown_subHeading_${rootClassName}`}
													>
														KonfHub Fee :
													</span>
													<span className="hide-price-text-two">
														{isNotINR ? "$" : "₹"} {platformFee}
													</span>
												</p>
												<p className="hide-prices-text d-flex justify-content-between mt-2 mb-0">
													<span
														className={`breakdown_subHeading_${rootClassName}`}
													>
														Payment Gateway Fee :
													</span>
													<span className="hide-price-text-two">
														{isNotINR ? "$" : "₹"} {totalPGFee}
													</span>
												</p>
											</div>
										)}
									</div>
									<div className="divider-line mt-4"></div>
									<p className="estimated-text d-flex justify-content-between mb-0 mt-2">
										<span>Estimated Earnings</span>
										<span>
											{isNotINR ? "$" : "₹"}{" "}
											{(
												Number(ticketPriceDetails.payableAmount) -
												Number(ticketPriceDetails.khFee)
											).toFixed(2)}
										</span>
									</p>
									<p className="ticket-calculation-bottom-text mt-3 mb-0">
										* Provided calculations are approx. and assumptions & might
										vary based on mode of payment
									</p>
								</div>
							</div>
						</div>
						<div className="row mb-2">
							<span className="astrix-text2  mt-4">
								KonfHub Fee & Payment Gateway Fee are inclusive of GST in this
								calculator.
							</span>
							<span className="astrix-text2  mt-1">
								* Ticket price calculator provides estimated pricing and breakdown
								for illustrative purposes only. Configurations made are not saved
								for your event.
							</span>
							<span className="astrix-text2  mt-1">
								* Payment gateway charges generally range from 2 to 3% based on mode
								of payment
							</span>
							<span className="astrix-text2  mt-1">
								* All fees are inclusive of GST at 18%
							</span>
						</div>
					</div>
					<div className="row bottom d-flex justify-content-center align-items-center bottom-section">
						<Link href="/pricing" target="_blank">
							<span className="text-center pointer">
								{`We ❤ Communities. Special rates for community-driven events!`}
								<i className="fas fa-arrow-right ms-4"></i>
							</span>
						</Link>
					</div>
				</div>
			</div>
			<style>
				{`
                    input { outline: none; }
                    .price-calc-icon{
                        font-size: 20px !important;
                        cursor: pointer;
                        margin-left:10px;
                    }            
                    .cost-container {
                        background: rgba(246, 249, 252, 1);
                        background-image: url(${Dots});
                        z-index: 500;
                        background-position: right 150px top 220px;
                        background-repeat: no-repeat;
                    }

                    .cost-container .awards-title {
                        color: #572148;
                        font-weight: 700;
                        font-size: 50px;
                        font-family: "Nunito";
                    }

                    .earning-desc{
                        font-family: Nunito;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 24.55px;
                        color: #636363;
                    }

                    .astrix-text{
                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 140%;
                        text-align: center;
                        color: #414141;
                        opacity: 0.74;
                    }
                    .col-7-main {
                        flex: 0 0 auto;
                        width: 58.3333333333%;
                    }
                    .col-5-main {
                        flex: 0 0 auto;
                        width: 41.6666666667%;
                    }
                    .astrix-text2{
                        font-family: Nunito;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 23.52px;
                        color: #572148bd;
                        /* or 22px */
                        text-align: center;
                        color: #572148;
                        opacity: 0.74;
                    }
                    .bottom-section{
                        min-height: 56px;
                        width: 80%;
                        border-radius: 0px 0px 8px 8px;
                        background: linear-gradient(90deg, #572148 0%, #FB5850 100%);
                    }

                    .bottom-section{
                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 140%;
                        text-align: center;
                        color: #F6F9FC;

                    }
                    .cost-container .awards-title .theme-text {
                        color: #FB5850;
                    }

                    .cost-container .awards-sub-title {
                        font-family: 'Nunito';
                        font-weight: 400;
                        font-size: 22px;
                        color: #636363;
                    }

                    .ticket-price-calc{
                        box-sizing: border-box;
                        padding: 30px 30px 0px 30px;
                        background: #FFFFFF;
                        border: 0.8px solid #ADADAD;
                        border-radius: 8px 8px 0px 0px;
                        width: 80%;
                    }

                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    margin: 0;
                    }

                    /* Firefox */
                    input[type=number] {
                        -moz-appearance: textfield;
                    }
                    .label_text{
                        font-family: Nunito;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 25.2px;
                        color: #572148;
                    }
                    .price-value-text{
                        font-family: 'Nunito';
                        font-weight: 700;
                        font-size: 27px;
                        line-height: 140%;
                        color: #FB5850;
                        border: none;
                        // width: 150px;
                    }
                    .label-text {
                        font-family: Nunito;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 25.2px;
                        color: #572148;
                    }
                    .limit-text{
                        font-family: 'Nunito';
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 140%;
                        color: #7D7D7D;
                    }
                    .paid_by_heading_${rootClassName} {
                        font-family: Nunito;
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 33.6px;
                        color: #572148;
                    }
                    .pricing_calc_checkbox{
                        width: 18px;
                        height: 18px;
                        border-radius: 2px;
                        opacity: 0px;
                        background: #572148;
                        color: #fff;
                    }
                    .tax-text{
                        font-family: 'Nunito';
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 140%;
                        color: #565656;
                    }
                    .taxPercentage-input{
                        height: 35px;
                        width: 40px;
                        border-radius: 4px;
                        font-family: Nunito;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 25.2px;
                        color: #572148;
                        padding: 5px 8px;
                        border: 1px solid #c4c4c4;
                    }
                    .tax_percentage_symbol {
                        font-family: Nunito;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 25.2px;
                        color: #572148;
                    }
                    .total-income{
                        font-family: Nunito;
                        font-size: 40px;
                        font-weight: 800;
                        line-height: 56px;
                        color: #fb5850;
                    }
                    .customSlider {
                        /* max width of your slider */
                        width: 100%;
                        /* Optional: Only If you want to center your slider in the page */
                        margin: auto;
                    }

                    .customSlider-track {
                        /* Top value to align your track to the center of your thumb */
                        top: 8px;
                        /* thickness of the track */
                        height: 5px;
                    /* default color of your track */
                        background: #C4C4C4;
                        border-radius: 7px;
                    }

                    .customSlider-track.customSlider-track-0 {
                        /* color of the track before the thumb */
                        background: linear-gradient(270deg, #FB5850 51.77%, #572148 83.63%);
                    }
                    .customSlider-thumb {
                        cursor: pointer;
                        /*color for the thumb */
                        background: #FB5850;
                        /* shape of the thumb: circle */
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        /* remove default outline when selected */
                        outline: none;
                    }

                    .customSlider-thumb:hover {
                        background: #FFFFFF;
                        border: 3px solid #FB5850;
                    }

                    .customSlider-mark.customSlider-mark-before {
                        background-color: linear-gradient(270deg, #FB5850 51.77%, #572148 83.63%);
                    }

                    .customSlider-mark.customSlider-mark-active {
                        display: none;
                    }
                    .ticket-calculation{
                        // text-align: center;
                        padding: 20px;
                        background: #FFFFFF;
                        box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.25);
                        border-radius: 10px;
                        width: 80%;
                        height: 78%;
                    }
                    .ticket-price{
                        font-family: 'Nunito';
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 140%;
                        color: #FB5850;
                    }
                    .divider{
                        border: 1px solid #E0E0E0;
                        margin-bottom: 11px
                    }
                    .konfhub-text{
                        font-family: 'Nunito';
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 140%;
                        color: #414141;
                        opacity: 0.74;
                    }
                    .other-fee-div{
                        margin-top: -5px;
                        padding: 10px;
                        border: 0.5px dotted #FB5850;
                        box-shadow: 0px 0px 7px rgba(251, 88, 80, 0.1);
                        border-radius: 10px;
                    }
                    .other-fee-text{
                        font-family: 'Nunito';
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 140%;
                        color: #414141;
                        opacity: 0.74;
                    }
                    .other-fee-amount{
                        color: #572148;
                        margin-left: 20px;
                    }
                    .breakdown_heading_${rootClassName} {
                        font-family: Nunito;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22.4px;
                        color: #414141;
                    }
                    .breakdown_subHeading_${rootClassName} {
                        font-family: Nunito;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 19.6px;
                        color: #414141;
                    }
                    .other-fee-tax{
                        font-family: 'Nunito';
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 140%;
                        color: #572148;
                    }
                    .Tab-text{
                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 140%;
                    }
                    .color-background{
                        background: linear-gradient(90deg, #FB5850 0%, #572148 100%);
                        color: #fff;
                        border:none;
                        cursor: default;
                        padding: 3px 15px;
                        border-top-left-radius: ${organiserKonfhubFee ? "5px" : participantKonfhubFee ? "0px" : "5px"};
                        border-bottom-left-radius: ${organiserKonfhubFee ? "5px" : participantKonfhubFee ? "0px" : "5px"};
                        border-top-right-radius: ${organiserKonfhubFee ? "0px" : participantKonfhubFee ? "5px" : "0px"};
                        border-bottom-right-radius: ${organiserKonfhubFee ? "0px" : participantKonfhubFee ? "5px" : "0px"};
                    }
                    .color-background-two{
                        background: linear-gradient(90deg, #FB5850 0%, #572148 100%);
                        color: #fff;
                        border:none;
                        cursor: default;
                        padding: 3px 15px;
                        border-top-left-radius: ${organiserTaxCharge ? "5px" : participantTaxCharge ? "0px" : "5px"};
                        border-bottom-left-radius: ${organiserTaxCharge ? "5px" : participantTaxCharge ? "0px" : "5px"};
                        border-top-right-radius: ${organiserTaxCharge ? "0px" : participantTaxCharge ? "5px" : "0px"};
                        border-bottom-right-radius: ${organiserTaxCharge ? "0px" : participantTaxCharge ? "5px" : "0px"};
                    }
                    .white-background-one{
                        color: #FB5850;
                        cursor: pointer;
                        border: 1px solid #ADADAD;
                        padding: 2px 15px;
                        border-left-style: none;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                    .white-background-two{
                        color: #FB5850;
                        cursor: pointer;
                        border: 1px solid #ADADAD;
                        padding: 2px 15px;
                        border-right-style: none;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }
                    .Organizer-text{
                        //   padding: 0px 15px;
                        //   border-radius: 5px;
                    }
                    .bottom-text{
                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 168%;
                        text-align: center;
                        color: rgba(87, 33, 72, 0.74);
                    }
                    .hide-prices{
                        background: rgba(225, 225, 225, 0.31);
                        padding: 5px 10px;
                    }
                    .hide-prices-text{
                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 140%;
                        color: #414141;
                        opacity: 0.74;
                    }
                    .ticket-calculation-bottom-text{
                        font-family: Nunito;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16.8px;
                        text-align: center;
                        color: #414141;
                        opacity: 0.74;
                        max-width:335px;
                    }
                    .estimated-text{
                        font-family: Nunito;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 22.4px;
                        color: #572148;
                    }
                    .price-text{
                        font-family: Nunito;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22.4px;
                        color: #572148;
                        text-align: right;
                        color: #572148;
                    }
                    .hide-price-text-two{
                        font-family: Nunito;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 19.6px;
                        text-align: right;
                        color: #572148;
                    }
                    .input-box{
                        border: 1.5px solid #c4c4c4;
                        border-radius: 4px;
                        width: 144px;
                        text-align: center;
                        margin: 10px 0
                    }
                    .from_limit_text_${rootClassName} {
                        font-family: Nunito;
                        font-size: 13px;
                        font-weight: 600;
                        line-height: 18.2px;
                        color: #7d7d7d;
                    }
                    .label-text_tax_applicable {
                        font-family: Nunito;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 22.4px;
                        color: #572148;
                        margin-bottom: 2.5px;
                    }
                    .divider-line{
                        border: 1px solid #E0E0E0;
                        margin-bottom: 11px
                    }
                    .icons{
                        margin-left: 8px;
                        cursor: pointer;
                    }
                    .currency-text{
                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 140%;
                        color: #572148;
                    }

                    .calc-flex{
                        gap: 30px;
                    }

                    .earning-head {
                        font-family: Nunito;
                        font-size: 33px;
                        font-weight: 700;
                        line-height: 45.01px;
                        color: #572148;
                    }

                    .custom_tabs {
                        width: 349px;
                        height: 46px;
                        padding: 8px 10px 8px 10px;
                        border-radius: 4px;
                        border: 1px solid #E2E2E2;
                        background: #F8F8F8;
                    }
                    .custom_tab {
                        height: 30px;
                        width: 104px;
                        background: #FFFFFF;
                        color: #572148;
                        font-family: Nunito;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22.4px;
                        border-radius: 2px;
                        cursor: pointer;
                    }
                    .custom_tab.active {
                        background: #572148;
                        color: #fff;
                    }


                    /* SM screens */
                    @media only screen and (min-width: 480px) and (max-width: 768px) {
                        .cost-container .awards-title {
                            font-size: 30px;
                        }

                        .cost-container .awards-sub-title {
                            font-size: 16px;
                        }
                        .tax-included{
                        justify-content:center;
                    }
                    }
                    @media only screen and (max-width: 768px){
                        .price-calc-icon{
                        font-size: 16px !important;
                        }
                    }

                    /* XS screens */
                    @media only screen and (max-width: 480px) {
                        .cost-container .awards-title {
                            font-size: 30px;
                        }

                        .cost-container .awards-sub-title {
                            font-size: 16px;
                        }
                        .ticket-price-calc{
                        width: 95% !important;
                    }
                    .tax-included{
                        justify-content:center;
                    }
                    .bottom{
                        width: 95% !important;

                    }
                    .total-income{
                        font-weight: 600;
                        font-size: 30px;
                        color: #FB5850;
                    }
                    .other-fee-text{
                        font-size: 14px;
                        color: #414141;
                        opacity: 0.74;
                    }
                    .price-text{
                        font-weight: 400;
                        font-size: 14px;
                        text-align: right;
                        color: #572148;
                    }
                    .hide-prices-text{
                        font-weight: 400;
                        font-size: 11px;
                        color: #414141;
                        opacity: 0.74;
                    }
                    .hide-price-text-two{
                        font-weight: 400;
                        font-size: 11px;
                        text-align: right;
                        color: #572148;
                    }
                    .estimated-text{
                        font-weight: 400;
                        font-size: 12px;
                        color: #572148;
                    }
                    .earning-head{
                        font-weight: 500;
                        font-size: 22px;
                        color: #572148;
                    }
                    .astrix-text2{
                        font-family: Nunito;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 23.52px;
                        color: #572148bd;
                        opacity: 0.74;
                    }
                    .bottom-section *{
                        font-weight: 500;
                        font-size: 17px;
                        text-align: center;
                        color: #F6F9FC;

                    }
                    .earning-desc{
                        font-weight: 500;
                        font-size: 16px;
                        color: #636363;
                    }
                    }

                    @media only screen and (max-width: 996px) {
                        .ticket-price-calc{
                            width: 90% !important;
                        }
                        .bottom-section{
                            width: 90% !important;
                        }
                        .calc-flex{
                            flex-direction:column;
                            align-items: center;
                            gap: 50px;
                        }
                        .flex-row-md{
                            flex-direction: row !important;
                        }
                        .col-7-main {
                        flex: 0 0 auto;
                        width: 100%;
                    }
                    .col-5-main {
                        flex: 0 0 auto;
                        width: 110%;

                    }
                    }
                    @media only screen and (max-width: 990px) {
                        .flex-row-md{
                            flex-direction: column !important;
                        }
                    }

                    @media only screen and (max-width: 1230px) {
                        .ticket-price-calc{
                            width: 90% !important;
                        }
                        .bottom-section{
                            width: 90% !important;
                        }
                    }
                    @media only screen and (max-width: 1050px) {
                        .earning-head{
                            font-weight: 600;
                            font-size: 27px;
                        }
                        .total-income{
                            font-weight: 600;
                            font-size: 36px;
                        }
                    }
                    @media (max-width: 426px){
                        .custom_tabs {
                            width: 297px;
                        }
                        .custom_tab {
                            width: 88px;
                            font-family: Nunito;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 19.6px;
                            text-align: center;
                        }
                        .ticket-calculation{
                            width: 90% !important;
                        }
                    }
                    @media (max-width: 376px){
                        .custom_tabs {
                            width: 262px;
                        }
                        .custom_tab {
                            width: 78px;
                            font-family: Nunito;
                            font-size: 11px;
                            font-weight: 600;
                            line-height: 19.6px;
                            text-align: center;
                        }
                        .ticket-calculation{
                            width: 90% !important;
                        }
                    }
                `}
			</style>
		</>
	);
};

export default HomeCostCalc;